/*
 * @Author: Whilding
 * @Date: 2022-07-22 09:56:34
 * @LastEditors: Whilding
 * @LastEditTime: 2022-07-22 18:02:17
 * @Description: file content
 */
import { createApp } from 'vue'
import App from './App.vue'


createApp(App).mount('#app')
