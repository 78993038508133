<!--
 * @Author: Whilding
 * @Date: 2022-07-22 09:56:34
 * @LastEditors: Whilding
 * @LastEditTime: 2022-07-22 10:06:57
 * @Description: file content
-->
<template>
  
  <HelloWorld/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
