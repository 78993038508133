<template>
  <div class="hello">
<p style='margin:0cm;text-align:center;font-size:14px;font-family:"Calibri",sans-serif;'><span style="font-size:16px;font-family:宋体;">请您认真阅读以下协议,在最下方同意后可继续操作：</span></p>
  <van-divider />
<p style='margin:0cm;text-align:justify;font-size:14px;font-family:"Calibri",sans-serif;'><span style="font-size:24px;font-family:宋体;">&nbsp;</span></p>
<p style='margin:0cm;text-align:center;font-size:14px;font-family:"Calibri",sans-serif;'><span style="font-size:21px;font-family:宋体;">洗衣烘干服务协议书</span></p>
<p style='margin:0cm;text-align:center;font-size:14px;font-family:"Calibri",sans-serif;'><span style="font-size:21px;font-family:方正小标宋简体;">&nbsp;</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;'><span style="font-size:16px;font-family:仿宋;">甲方：洗衣烘干服务的缴费用户</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;'><span style="font-size:16px;font-family:仿宋;">乙方：海口龙华宇扬后勤服务部</span></p>
<br>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;text-indent:32.0pt;'><span style="font-size:16px;font-family:仿宋;">在校园生活中，洗衣服的问题总是困扰着同学们，同学们学习生活时间安排比较紧，许多大件衣服或床单之类的手洗费时费力，而且效果不是好，到了冬天气温下降，更是加剧了同学们的洗衣&ldquo;危机&rdquo;。</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;text-indent:32.0pt;'><span style="font-size:16px;font-family:仿宋;">根据调查显示有90％以上的住校生希望有智能洗衣机集洗、烘干、杀菌为一体。本着互惠互利的原则，甲乙双方经友好协商，乙方为甲方提供洗衣烘干服务事宜，共同达成以下条款，已便双方共同履行。</span></p><br>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;'><strong><span style="font-size:16px;font-family:仿宋;">第一条</span></strong></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;'><span style="font-size:16px;font-family:仿宋;">甲乙双方合作期限为一学期，每学期缴费陆佰伍拾元整（&yen;650.00元），甲方一次性通过转账方式支付乙方洗涤费用。</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;'><strong><span style="font-size:16px;font-family:仿宋;">第二条</span></strong></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;'><span style="font-size:16px;font-family:仿宋;">甲方的权利与义务：</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;text-indent:24.0pt;'><span style="font-size:16px;font-family:仿宋;">1</span><span style="font-size:16px;font-family:仿宋;">、甲方应保证物品的完好，如洗前发现物品有破损应及时告知乙方。</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;text-indent:24.0pt;'><span style="font-size:16px;font-family:仿宋;">2</span><span style="font-size:16px;font-family:仿宋;">、甲乙双方对洗涤物品各自有进行检查验收的义务。</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;text-indent:24.0pt;'><span style="font-size:16px;font-family:仿宋;">3</span><span style="font-size:16px;font-family:仿宋;">、甲方应配合乙方工作，洗涤物品所有问题应在乙方送回洗衣物后的二十四小时内提出，待双方确认后进行处理。</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;'><strong><span style="font-size:16px;font-family:仿宋;">第三条</span></strong></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;'><span style="font-size:16px;font-family:仿宋;">乙方的权利与义务：</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;margin-left:21.0pt;'><span style="font-size:16px;font-family:仿宋;">1</span><span style="font-size:16px;font-family:仿宋;">、乙方有权利按本协议规定向甲方收取洗涤费。</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;margin-left:21.0pt;'><span style="font-size:16px;font-family:仿宋;">2</span><span style="font-size:16px;font-family:仿宋;">、乙方负责甲方衣服的洗涤、烘干、整洁；</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;text-indent:21.0pt;'><span style="font-size:16px;font-family:仿宋;">3</span><span style="font-size:16px;font-family:仿宋;">、乙方保证甲方送洗衣服的送洗质量及数量，甲方如果报告衣物发生损坏和丢失，乙方应在二十四小时内给与答复，未能解决的须按质进行合理赔偿。</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;'><strong><span style="font-size:16px;font-family:仿宋;">第四条</span></strong></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;margin-left:21.0pt;'><span style="font-size:16px;font-family:仿宋;">1</span><span style="font-size:16px;font-family:仿宋;">、本协议自用户缴费成功后生效，具有法律效力，甲乙双方均不得随意变更或者解除本协议，需要变更或解除时，须经双方协商一致达成新的书面协议；</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;margin-left:21.0pt;'><span style="font-size:16px;font-family:仿宋;">2</span><span style="font-size:16px;font-family:仿宋;">、双方期满，甲乙双方可通过平等协商是否再进行续约，如双方同意续约将再另行签订协议；如有一方不同意续约，需提前一个月通知对方，如协议有效期间内甲方违约，需说明理由并赔偿乙方一个月洗涤费用。</span></p>
<p style='margin:0cm;text-align:left;font-size:14px;font-family:"Calibri",sans-serif;margin-left:21.0pt;'><span style="font-size:16px;font-family:仿宋;">3</span><span style="font-size:16px;font-family:仿宋;">、因不可抗拒因素导致协议无法履行经协商一致，可以终止协议。</span></p>
<br>
<p style='margin:0cm;text-align:right;font-size:14px;font-family:"Calibri",sans-serif;'><span style="font-size:16px;font-family:仿宋;">&nbsp; &nbsp;</span><span style="font-size:16px;font-family:仿宋;">{{this.nowTime}}</span></p>
<div>
  <van-divider />
<van-row type="flex" justify="center">
  <van-checkbox v-model="isChecked" shape="square">我已阅读并同意《洗衣烘干服务协议书》</van-checkbox>
  </van-row>
  <br>
<van-button size="large" :disabled="!isChecked" type="primary" url="https://wap.psbc.com/mobilebank/getInfo.do?type=addQrcodePayment&payItemNo=NDQ2MDE0MDYwMDAxMzE%3D&cityInfo=NDYs">前往缴费</van-button>    
</div>
  <van-divider />
</div>
  
</template>

<script>
import { Checkbox, Row,Divider,Button} from 'vant';
export default {
  name: 'HelloWorld',
  components: {
    VanCheckbox:Checkbox,
    VanRow:Row,
    VanDivider:Divider,
    VanButton:Button
  },
  props: {
    msg: String
  },
  mounted(){
    let nowDate = new Date()
		let date = {
			year: nowDate.getFullYear(),
			month: nowDate.getMonth() + 1,
			date: nowDate.getDate()
			}	 
			if (parseInt(date.date) < 10) {
			date.date = '0' + date.date
			}
			this.nowTime = date.year + '年' + date.month + '月' + date.date + '日'
      console.log(this.nowTime)
  },
  data(){
    return{
      nowTime:null,
      isChecked:false
    }
  },
  methods:{
handleDisabled:function(){
                    this.isChecked = !this.isChecked;
                    if(this.isChecked==true){
                        this.isDisabled =  true;
                    }
                    else{
                        this.isDisabled =  false;}}
                      

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
